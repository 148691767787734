.transfer__pax-info {
  @include create-pax-info;

  &_row {
  	border-top: 1px solid #087dc2;
  }

  &_footer {
    @include clearfix;
    .transfer-pax-info {
      &__button {
        @include make-one-third;
        padding-left: 0;
        padding-right: 0;
        min-height: 1px;
        float: left;
      }
    }
  }
  &_save-traveler{
	 margin-bottom:0.9375rem;
  }
}
.pax-info{
  @include create-search-form;
}
.view__cart {
  float: right;
  padding: $base-padding/6;
}
.transfer__pax-info_main {
  margin-left: 0;
}

.transfer__pax-info_card_content{
.transfer {
  
  @include create-ssr-styles;
}
}
