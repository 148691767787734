.transfer__review {
  @include create-main-wrapper;
  @include create-row-card;
  &_main {
    padding-top: $base-padding/2;
    .content {
      padding-left: $base-padding/2;
      padding-right: 0;
    }
     .book-card__price-amount {
	  	font-family: $font-family-number;
	    font-size: 1.125rem;
	    color: #ec2227;
	  }
  }

  &_card {
    box-shadow: $row-shadow-color;
    @include create-review-nav;
	.nav > li > a {
    	padding: 0.625rem 0.894rem;
	}
  }

  &_wrapper {
    .search-location_autosuggestion .itemcontainer {
      left: 0;
      right: 0;
    }
    .autosuggestion__item_title{
      font-size: 12px;
    }
  }

}

.card {
  &__extra-parameters {
    @include clearfix;
    padding: 10px;
    
    &_flight-time,&_flight-number {
      @include make-one-half;
      padding-left: 0;
      padding-right: 0;
      min-height: 1px;
      float: left;
    }

    .flight-number {
      @include clearfix;
      &__label,&__value {
        float:left;
        padding-left: 0.7rem;
      }
    }

    &_flight-time {
      .search__time_select {
          >select {
         background: 0 0;
         font-size: .875rem;
         vertical-align: middle;

       }
     }
    }
    
  }
  
  &__transfer-extra-parameters {
  	@include make-row;
  	@include clearfix;
  	
  	padding-top: $base-padding/3;
  	padding-bottom: $base-padding/3;
  	
  	
  	&_airport-block, &_hotel-block, &_other-block, &_port-block {
  		@include make-one-fourth;
  	}
  	
  	&_station-block {
  		@include make-one-fourth;
  	}
  	
  	&_heading, &_data {
  		float: left;
  		width: 100%;
  		padding: 5px;
  		.asterisk {
  		  float: left;
          padding-left: 2px;
  		}
  		.transfer-origin-airport , .transfer-dropoff-hotel , .transfer-origin-flight-number,.transfer-destination-flight-number,.transfer-destination-airport,.transfer-origin-port,.transfer-ship-name,.transfer-dropoff-port,.transfer-dropoff-station {
          float: left;
          
          >input, select{
          	padding: 0.125rem;
          }
  		}
  		.info-icon{
	      	font-size: 1rem;
			color: $brand-color-2;
			padding-left: 0.5rem;
        }
  	}
  	
  	&_heading{
  		color: $brand-color-2;
  	}
  	
  	&_transfer-pickup-station, &_transfer-dropoff-station {
		float: left;
		padding: 0.3125rem;
  		margin-right: $base-margin/3;
		width: 100%;  
		
		>select {
			width: 100%;
		}		
  	}
  	
  }
  &__info-message {
  	@include clearfix;
    padding-top: 1rem;
    padding-left: 0.3rem;
    color: $brand-color-1;
  }
  
}

.transfer__tab-content {
  padding: 10px;
  text-transform: capitalize;
  &_notes {
  	padding: 5px;
  	.notes__terms-and-condition-link {
      font-size: 1rem;
    }
  }
  &_heading {
    text-transform: uppercase;
    color: $brand-color-2;
    margin-left: -10px;
  }
  &_data {
  
	.transfer-extra {
		padding-top: 10px;

		&__heading {
			background-color:$light-gray;
			text-transform: uppercase;
			@include clearfix;
			@include make-row;
			border-bottom: 0.1rem $gray-light solid;
			&_name {
				padding: 0.25rem;
				float: left;
				width: 55%;
			}
			&_max-quantity{
				padding: 0.25rem;
				float: left;
				width: 15%;
			}
			&_quantity {	
				padding: 0.25rem;
				float: left;
				width: 15%;
			}
			&_price {
				padding: 0.25rem;
				float: left;
				width: 15%;
				text-align: center;
			}
	    }

		&__data {
			@include clearfix;
			@include make-row;
			margin-top: 0.3rem;
			border-bottom: 0.1rem $gray-light solid;
			&_name {
				padding: 0.25rem;
				float: left;
				width: 60%;
			}
			&_max-quantity{
				padding: 0.25rem;
				float: left;
				width: 5%;
			}
			
			&_quantity {	
				padding: 0.25rem;
				float: left;
				width: 15%;
				text-align: center;
				>input {
		            width: 30%;
		            border-radius: 0.25rem;
    				border-color: $gray;
    				text-align: center;
    				margin-left: 1rem;
		        }
			}
			
			&_icon{
				padding: 0.7rem;
				float: left;
				width: 5%;
			}
			
			&_price {
				padding: 0.25rem;
				float: left;
				width: 15%;
				text-align: center;
			}
		}
		
		&__amount {
			@include clearfix;
			@include make-row;
			margin-top: 0.3rem;
			background-color:$light-gray;
			&_label{
				padding: 0.25rem;
				float: left;
				width: 70%;
			}
			&_total-amount{
			    padding: .25rem;
			    float: right;
			    width: 10%;			
			}
		}
		&__save {
			color: white;
			padding: 0 5rem 0 5rem;
			text-align: center;
			display: inline-block;
			font-size: 16px;
			margin: 4px 2px;
			cursor: pointer;
			background-color:$water-blue;  
			margin: 1rem 0 1rem 0;
			float: right;
		}
		
	}
    .transfer-overview {
      padding-top: 10px;

      &__data {
      	margin-bottom: 30px;
      }

      &__heading,
      &__data {
        @include clearfix;
        @include make-row;
        &_transfer-name {
          padding: 5px;
          float: left;
          width: 35%;
        }
        &_transfer-city {
          padding: 5px;
          float: left;
          width: 30%;
        }
        &_transfer-date {
          padding: 5px;
          float: left;
          width: 35%;
           .search__time_select {
	          	 >select {
	            width: 75%;
	            background: 0 0;
	            font-size: .875rem;
	            vertical-align: middle;

	          }
          }
        }
        &_transfer-origin-hotel  {
          padding: 5px;
          float: left;
          width: 35%;
        }
        &_transfer-origin-airport {
          padding: 5px;
          float: left;
          width: 50%;
        }
        &_other-address, &_other-pin-code {
          padding: 5px;
          float: left;
          width: 50%;
        }
        &_transfer-origin-flight-number {
          padding: 5px;
          float: left;
          width: 50%;
        }
        &_transfer-pickup-station {
          padding: 5px;
          float: left;
          width: 35%;

          >select {
            width: 75%;
            background: 0 0;
            font-size: .875rem;
            vertical-align: middle;
          }
        }
        &_transfer-origin-city {
          padding: 5px;
          float: left;
          width: 30%;
        }
        &_transfer-train-name,&_transfer-train-no {
          padding: 5px;
          float: left;
          width: 25%;
        }
        &_transfer-ship-name {
          padding: 5px;
          float: left;
          width: 35%;
        }
        &_transfer-dropoff-hotel, &_transfer-dropoff-port {
          padding: 5px;
          float: left;
          width: 35%;
        }
        &_transfer-destination-airport {
          padding: 5px;
          float: left;
          width: 50%;
        }
        &_transfer-destination-flight-number {
          padding: 5px;
          float: left;
          width: 50%;
        }
        &_transfer-dropoff-station {
          padding: 5px;
          float: left;
          width: 35%;

          >select {
            width: 75%;
            background: 0 0;
            font-size: .875rem;
            vertical-align: middle;
          }
        }
        &_transfer-destination-city {
          padding: 5px;
          float: left;
          width: 30%;
        }
      }
      &__heading {
        background-color: $brand-color-4;
        text-transform: uppercase;
      }
    }
    .transfer-pricing {
      margin-left: -.5rem;
      
      &__title {
	    color: $brand-color-2;
	    font-size: 1rem;
	    padding: .25rem;
	  }
	  .inclusive_vat
	  {
	  float: right;
      font-size: 65%;
      color: red;
      text-transform: uppercase;
	  }
      &__data {
      	margin-bottom: .5rem;
      }
      &__header,
      &__data {
        @include clearfix;
        &_title1 {
          @include make-one-sixth;
          padding: 5px;
        }
        &_title2 {
          @include make-one-sixth;
          padding: 5px;
          text-align: right;
        }
        
        &_title3{
          @include make-one-sixth;
          padding: 5px;
          text-align: right;
        }
        &_title4 {
		  @include make-one-sixth;
          padding: 5px;
          text-align: right;
        }
        &_title5 {
          @include make-one-sixth;
          padding: 5px;
          text-align: right;
        }
      }
      
      &__data {
      	font-family: $font-family-number;
      }
      
      &__header {
        border-bottom: 1px solid $gray-lighting;
        color: $gray;
        text-transform: uppercase;
      }
    }



    .transfer-inclusion,.transfer-exclusion{
    margin-left:-10px;
&__heading{
color: $brand-color-2;
text-transform: capitalize;
font-size: 0.875rem;
padding-top: 0.9375rem;
padding-bottom: 0.625rem;

}
&__data{
padding: 5px;
text-transform: capitalize;

}

    }
  }
}

.cancellation-policy
{
    text-transform: none;
}

.transfer-destination-airport{
	@include clearfix();
	&__time-input,&__icon{
		float:left;
	}
	&__icon{
		padding: 0.3125rem 0;
	}
}